import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent, ReplaySubject, Observable } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DOCUMENT_TYPES } from '../../helper/RimVrsteDokumenata';
import { AccountService } from './account.service';
import { SuperAdmin } from '../../_constants/ApplicationRoles';
import { FIRME_ADMINISTRATION_PREVIEW } from '../routes/permissions';
import { TranslateService } from '@ngx-translate/core';

const SIDEBAR_KEY = 'sidebar';

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root',
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );
  public taskAdded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // Search Box
  public search: boolean = false;
  // public searchDropdownSubj: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  private _collapseSidebar: boolean = window.innerWidth < 1140 ? true : false;

  firmaId;

  get collapseSidebar(): boolean {
    if (localStorage.getItem(SIDEBAR_KEY)) {
      return localStorage.getItem(SIDEBAR_KEY) !== 'false';
    }
    return this._collapseSidebar;
  }

  set collapseSidebar(value: boolean) {
    this._collapseSidebar = value;
    localStorage.setItem(SIDEBAR_KEY, String(value));
  }


  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 1140 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router, private accountService: AccountService,
    private translate: TranslateService) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(debounceTime(7000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 1140) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 1140) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next(null);
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  public setTaskAdded(added): void {
    this.taskAdded.next(added);
  }

  MENUITEMS: Menu[] = [
    {
      title: this.translate.instant('DASHBOARD.Fakture'),
      type: 'sub',
      icon: 'file-text',
      active: false,
      badgeType: "fakture", //bitno za onboard
      children: DOCUMENT_TYPES.map((t) => {
        return { path: t.link + `${t.value}`, title: this.translate.instant('DASHBOARD.' + t.name), type: 'link' };
      }), //path bitan za anchor
    },

    {
      title: this.translate.instant('DASHBOARD.BrzaProdaja'),
      type: 'link',
      icon: 'bar-chart-2',
      active: false,
      path: '/brza-prodaja',
    },

    {
      title: this.translate.instant('DASHBOARD.Sifrarnici'),
      type: 'sub',
      icon: 'list',
      active: false,
      badgeType: "sifrarnici", //bitno za onboard
      children: [
        { path: `/clients`, title: this.translate.instant('DASHBOARD.Komitenti'), type: 'link' },
        { path: `/articles`, title: this.translate.instant('DASHBOARD.Artikli'), type: 'link' }, //path bitan za anchor
        {
          path: `/poslovne-jedinice`,
          title: this.translate.instant('DASHBOARD.PoslovneJedinice'),
          type: 'link',
        },
        { path: `/podgrupe`, title: this.translate.instant('DASHBOARD.Podgrupe'), type: 'link' },
        { path: `/grupe`, title: this.translate.instant('DASHBOARD.Grupe'), type: 'link' },
        { path: `/gradovi`, title: this.translate.instant('DASHBOARD.Gradovi'), type: 'link' },
        { path: `/kantoni`, title: this.translate.instant('DASHBOARD.Kantoni'), type: 'link' },
        { path: `/postavke`, title: this.translate.instant('DASHBOARD.Postavke'), type: 'link' },
        { path: `/komercijalisti`, title: this.translate.instant('DASHBOARD.Komercijalist'), type: 'link' },
        { path: `/napomene`, title: this.translate.instant('DASHBOARD.Napomene'), type: 'link' },
        {
          path: `/pdv/kif`, title: this.translate.instant('DASHBOARD.Knjige izlaznih faktura'), type: 'link',
        },
        { path: `/pdv/kuf`, title: this.translate.instant('DASHBOARD.Knjige ulaznih faktura'), type: 'link', },
        { path: `/financije/fin-dokumenti`, title: this.translate.instant('DASHBOARD.Financijski dokumenti'), type: 'link' },
        { path: `/financije/fin-nalozi`, title: this.translate.instant('DASHBOARD.Financijski nalozi'), type: 'link' },
        { path: `/rim/dokumenti`, title: this.translate.instant('DASHBOARD.RIM dokumenti'), type: 'link' },
        { path: `/kontni-plan`, title: this.translate.instant('DASHBOARD.Kontni plan'), type: 'link' },
        { path: `/dms/tipovi`, title: this.translate.instant('DASHBOARD.DMS Tipovi'), type: 'link' },
        { path: `/infos/ziro-racuni`, title: this.translate.instant('DASHBOARD.Žiro računi'), type: 'link' },
        { path: `/financije/projekti`, title: this.translate.instant('DASHBOARD.Financijski projekti'), type: 'link' },
        { path: `/amortizacijske-skupine`, title: this.translate.instant('DASHBOARD.Amortizacijske skupine'), type: 'link' },
        { path: `/stalna-sredstva`, title: this.translate.instant('DASHBOARD.Osnovna sredstva'), type: 'link' },

      ],
    },
    {
      title: this.translate.instant('DASHBOARD.RobnoMaterijalno'),
      type: 'sub',
      icon: 'book',
      active: false,
      badgeType: 'robnomaterijalno',
      children: [
        {
          title: this.translate.instant('DASHBOARD.Kalkulacija'),

          type: 'link',
          path: '/invoice/calculation-list/2',
        },
        {
          title: this.translate.instant('DASHBOARD.UvoznaKalkulacija'),
          type: 'link',
          path: '/invoice/calculation-list/32',
        },
        {
          title: this.translate.instant('DASHBOARD.PocetnoStanje'),
          type: 'link',
          path: '/pocetno-stanje',
        },
        { title: 'Fiskalizacija', type: 'fiscalizationModal' },
        { path: `/stock`, title: this.translate.instant('DASHBOARD.Lager'), type: 'link' },
        { path: `/trgovacka-knjiga`, title: this.translate.instant('DASHBOARD.Trgovačka knjiga'), type: 'link' },

        { path: `/kartica-artikla`, title: this.translate.instant('DASHBOARD.Kartica artikla'), type: 'link' },
        {
          title: this.translate.instant('DASHBOARD.Izvjestaji'),
          type: 'sub',
          children: [
            { path: `/report`, title: this.translate.instant('DASHBOARD.IzvjestajPrometa'), type: 'link' },
            { path: `/report-users`, title: this.translate.instant('DASHBOARD.IzvjestajPoOperaterima'), type: 'link' },
          ],
        },
        { path: `/infos/prijemni-list`, title: this.translate.instant('DASHBOARD.Prijemni list'), type: 'link' },
        { path: `/rim/unos-popisa`, title: this.translate.instant('DASHBOARD.Unos popisa'), type: 'link' },
        { path: `/rim/nivelacije-cijena`, title: this.translate.instant('DASHBOARD.Nivelacije cijena'), type: 'link' },
        { path: `/rim/predispozicije`, title: this.translate.instant('DASHBOARD.Predispozicije'), type: 'link' },
        { path: `/rim/ulazne-predispozicije`, title: this.translate.instant('DASHBOARD.Ulazne predispozicije'), type: 'link' },
      ],
    },

    {
      title: this.translate.instant('DASHBOARD.Financije'),
      type: 'sub',
      icon: 'dollar-sign',
      active: false,
      badgeType: "financije", //bitno za onboard
      children: [
        {
          path: `/financije/uknjizeni-nalozi`,
          title: this.translate.instant('DASHBOARD.Uknjiženi nalozi'),
          type: 'link',
        },
        {
          path: `/financije/pripremni-nalozi`,
          title: this.translate.instant('DASHBOARD.Unos naloga'),
          type: 'link',
        },
        {
          path: `/financije/kupci-dobavljaci`,
          title: this.translate.instant('DASHBOARD.KupciDobavljaciSalda'),
          type: 'link',
        },
        {
          path: `/financije/potrazivanja-po-valuti`,
          title: this.translate.instant('DASHBOARD.PotrazivanjaPoValuti'),
          type: 'link',
        },
        { title: 'IOS', type: 'IosModal' },
        {
          path: `/financije/dnevnik-financijskog-knjizenja`, title: this.translate.instant('DASHBOARD.Dnevnik financijskog knjiženja'), type: 'link',
        },

        {
          path: `/financije/bruto-bilans-datum`, title: this.translate.instant('DASHBOARD.Bruto bilans po datumu'), type: 'link',
        },

        { path: `/financije/auto-knjizenja`, title: this.translate.instant('DASHBOARD.Sheme knjiženja'), type: 'link' },

        { title: this.translate.instant('DASHBOARD.Uknjiži dokumente iz RIM-a'), type: 'autoKnjizenjeIzRim' },

      ],
    },

    {
      title: this.translate.instant('DASHBOARD.PDV modul'),
      type: 'sub',
      icon: 'cpu',
      active: false,
      badgeType: 'pdv',
      children: [
        { path: `/pdv/kif-podaci`, title: this.translate.instant('DASHBOARD.Izlazne fakture'), type: 'link', },
        { path: `/pdv/kuf-podaci`, title: this.translate.instant('DASHBOARD.Ulazne fakture'), type: 'link', },
        { title: this.translate.instant('DASHBOARD.Štampaj KIF po datumu'), type: 'kifPoDatumuModal' },
        { title: this.translate.instant('DASHBOARD.Štampaj KUF po datumu'), type: 'kufPoDatumuModal' },
        { title: this.translate.instant('DASHBOARD.PDV Prijava'), type: 'pdvPrijavaModal' },
        { title: this.translate.instant('DASHBOARD.Automatsko knjiženje iz RIM-a'), type: 'autoKnjizenjeRimPdvModal' },
        { title: this.translate.instant('DASHBOARD.Izvezi e-isporuke'), type: 'ExportEIsporuke' },
        { title: this.translate.instant('DASHBOARD.Izvezi e-nabavke'), type: 'ExportENabavke' },
      ],
    },


    {
      title: this.translate.instant('DASHBOARD.Plaće'),
      type: 'sub',
      icon: 'target',
      active: false,
      badgeType: 'place',
      children: [
        { path: `/radnici`, title: this.translate.instant('DASHBOARD.Radnici'), type: 'link' },
        { path: `/obracuni-placa`, title: this.translate.instant('DASHBOARD.Obračuni plaća'), type: 'link' },
       /*  { title: this.translate.instant('PROPERTIES.Platne liste radnika'), type: 'platneListeRadnika' }, */
        {
          title: this.translate.instant('DASHBOARD.Sifrarnici'), type: 'sub',
          children: [
            { path: `/statusi-radnika`, title: this.translate.instant('DASHBOARD.Statusi radnika'), type: 'link' },
            { path: `/poslovne-banke`, title: this.translate.instant('DASHBOARD.Poslovne banke'), type: 'link' },
            { path: `/kvalifikacije`, title: this.translate.instant('DASHBOARD.Kvalifikacije'), type: 'link' },
            { path: `/radna-mjesta`, title: this.translate.instant('DASHBOARD.Radna mjesta'), type: 'link' },
            { path: `/radne-jedinice`, title: this.translate.instant('DASHBOARD.Radne jedinice'), type: 'link' },
            { path: `/parametri-obracuna`, title: this.translate.instant('DASHBOARD.Parametri obračuna'), type: 'link' },
            { path: `/sheme-obracuna`, title: this.translate.instant('DASHBOARD.Sheme obračuna'), type: 'link' },
            { path: `/grupe-obustava`, title: this.translate.instant('DASHBOARD.Grupe obustava'), type: 'link' },
            { path: `/obustave`, title: this.translate.instant('DASHBOARD.Obustave'), type: 'link' },
            { path: `/naknade`, title: this.translate.instant('DASHBOARD.Naknade'), type: 'link' },
            { path: `/porezi`, title: this.translate.instant('DASHBOARD.Porezi'), type: 'link' },
            { path: `/doprinosi`, title: this.translate.instant('DASHBOARD.Doprinosi'), type: 'link' },
            { path: `/hr/auto-knjizenja`, title: this.translate.instant('DASHBOARD.Auto knjiženja plaća'), type: 'link' },
          ]
        },

      ],
    },

    {
      title: 'DMS',
      type: 'sub',
      icon: 'briefcase',
      active: false,
      badgeType: 'dms',
      children: [
        {
          path: `/dms/dms-dokumenti`, title: this.translate.instant('DASHBOARD.DMS Dokumenti'), type: 'link',
        },
      ]
    },

    {
      title: 'Osnovna sredstva',
      type: 'sub',
      icon: 'truck',
      active: false,
      badgeType: 'osnovna_sredstva',
      children: [
        {
          path: `/stalna-sredstva`, title: this.translate.instant('DASHBOARD.Osnovna sredstva'), type: 'link',
        },
        { title: this.translate.instant('DASHBOARD.Salda kartica'), type: 'saldaKarticaSS', },
        { path: `/stalna-sredstva/amortizacije`, title: this.translate.instant('DASHBOARD.Amortizacije'), type: 'link', },
        { path: `/stalna-sredstva/izlaz-otpis`, title: this.translate.instant('DASHBOARD.Izlaz/Otpis osnovnih sredstava'), type: 'link', },
      ]
    },

    {
      title: this.translate.instant('DASHBOARD.UvozPodataka'),
      type: 'link',
      icon: 'upload',
      active: false,
      path: '/import-data/import-document',
    },


    {
      title: 'Fiskus field',
      type: 'sub',
      icon: 'globe',
      active: false,
      children: [
        { path: `/fiskus-field/calendar`, title: this.translate.instant('DASHBOARD.Fiskus field kalendar'), type: 'link' },

        {
          title: this.translate.instant('DASHBOARD.Sifrarnici'),
          type: 'sub',
          icon: 'list',
          active: false,
          badgeType: "ff-sifrarnici", //bitno za onboard
          children: [
            { path: `/fiskus-field/tipovi-formi`, title: this.translate.instant('DASHBOARD.Tipovi formi'), type: 'link' },
            { path: `/fiskus-field/polja`, title: this.translate.instant('DASHBOARD.Fiskus field polja'), type: 'link' },
            { path: `/fiskus-field/tagovi`, title: this.translate.instant('DASHBOARD.Komitent tagovi'), type: 'link' },

          ]
        },

        { path: `/fiskus-field/tasks`, title: this.translate.instant('DASHBOARD.Zadaci'), type: 'link' },

        { path: `/fiskus-field/obilasci`, title: this.translate.instant('DASHBOARD.Obilasci'), type: 'link' },
        {
          path: `/fiskus-field/neobidjeni-komitenti`,
          title: this.translate.instant('DASHBOARD.NeobidjeniKomitenti'),
          type: 'link',
        },
        {
          path: `/fiskus-field/obilasci-forme-sum`,
          title: this.translate.instant('DASHBOARD.ObilasciKomitentiFormeSum'),
          type: 'link',
        },
        { path: `/fiskus-field/narudzbe`, title: this.translate.instant('DASHBOARD.Narudzbe'), type: 'link' },
      ],
    },

    ...this.superAdminItems,
    ...this.firmMenu,
    ...this.WooCommerceMenu,
  ];

  MEGAMENUITEMS: Menu[] = [
    {
      title: 'Error Pages',
      type: 'sub',
      active: true,
      children: [
        {
          path: '/error-page/error-400',
          title: 'Error Page 400',
          type: 'link',
        },
        {
          path: '/error-page/error-401',
          title: 'Error Page 401',
          type: 'link',
        },
        {
          path: '/error-page/error-403',
          title: 'Error Page 403',
          type: 'link',
        },
        {
          path: '/error-page/error-404',
          title: 'Error Page 404',
          type: 'link',
        },
        {
          path: '/error-page/error-500',
          title: 'Error Page 500',
          type: 'link',
        },
        {
          path: '/error-page/error-503',
          title: 'Error Page 503',
          type: 'link',
        },
      ],
    },
    {
      title: 'Authentication',
      type: 'sub',
      active: false,
      children: [
        {
          path: '/authentication/simple-login',
          title: 'Login Simple',
          type: 'link',
        },
        {
          path: '/authentication/login-with-background-image',
          title: 'Login BG Image',
          type: 'link',
        },
        {
          path: '/authentication/login-with-background-video',
          title: 'Login BG Video',
          type: 'link',
        },
        {
          path: '/authentication/simple-register',
          title: 'Simple Register',
          type: 'link',
        },
        {
          path: '/authentication/register-with-background-image',
          title: 'Register BG Image',
          type: 'link',
        },
        {
          path: '/authentication/register-with-background-video',
          title: 'Register BG Video',
          type: 'link',
        },
      ],
    },
    {
      title: 'Usefull Pages',
      type: 'sub',
      active: false,
      children: [
        { path: '/search-pages', title: 'Search Pages', type: 'link' },
        {
          path: '/authentication/unlock-user',
          title: 'Unlock User',
          type: 'link',
        },
        {
          path: '/authentication/forgot-password',
          title: 'Forgot Password',
          type: 'link',
        },
        {
          path: '/authentication/reset-password',
          title: 'Reset Password',
          type: 'link',
        },
        { path: '/maintenance', title: 'Maintenance', type: 'link' },
      ],
    },
    {
      title: 'Email templates',
      type: 'sub',
      active: false,
      children: [
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/basic-template.html',
          title: 'Basic Email',
          type: 'extTabLink',
        },
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/email-header.html',
          title: 'Basic With Header',
          type: 'extTabLink',
        },
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/template-email.html',
          title: 'Ecomerce Template',
          type: 'extTabLink',
        },
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/template-email-2.html',
          title: 'Email Template 2',
          type: 'extTabLink',
        },
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html',
          title: 'Ecommerce Email',
          type: 'extTabLink',
        },
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/email-order-success.html',
          title: 'Order Success',
          type: 'extTabLink',
        },
      ],
    },
    {
      title: 'Coming Soon',
      type: 'sub',
      active: false,
      children: [
        { path: '/coming-soon/simple', title: 'Coming Simple', type: 'link' },
        {
          path: '/coming-soon/simple-with-bg-img',
          title: 'Coming BG Image',
          type: 'link',
        },
        {
          path: '/coming-soon/simple-with-bg-vid',
          title: 'Coming BG Video',
          type: 'link',
        },
      ],
    },
  ];

  LEVELMENUITEMS: Menu[] = [
    {
      path: '/file-manager',
      title: 'File Manager',
      icon: 'git-pull-request',
      type: 'link',
    },
    {
      title: 'Users',
      icon: 'users',
      type: 'sub',
      active: false,
      children: [
        {
          path: '/user/team-details',
          title: 'All Users',
          icon: 'users',
          type: 'link',
        },
        {
          path: '/user/profile',
          title: 'User Profile',
          icon: 'users',
          type: 'link',
        },
        {
          path: '/user/edit-profile',
          title: 'Edit Profile',
          icon: 'users',
          type: 'link',
        },
      ],
    },
    { path: '/bookmarks', title: 'Bookmarks', icon: 'heart', type: 'link' },
    { path: '/calender', title: 'Calender', icon: 'calendar', type: 'link' },
    { path: '/social-app', title: 'Social App', icon: 'zap', type: 'link' },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
  levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

  get WooCommerceMenu(): Menu[] {
    this.firmaId = this.accountService.context?.organizationId?.toString() || '';
    if (this.firmaId == 1) {
      return [
        {
          title: this.translate.instant('DASHBOARD.WooCommerce Sinc'),
          type: 'link',
          icon: 'cloud',
          active: false,
          path: '/woo-sync',
        },
      ];
    }
    return [];
  }

  get firmMenu(): Menu[] {
    if (this.accountService.roleClaims?.length == undefined) return [];

    const haveAccessFirms = this.accountService.roleClaims?.find(
      (r) => r === FIRME_ADMINISTRATION_PREVIEW
    );

    if (haveAccessFirms) {
      return [
        {
          title: this.translate.instant('DASHBOARD.Firme'),
          type: 'link',
          icon: 'database',
          path: '/firms',
        },
      ];
    }
    return [];
  }

  get superAdminItems(): Menu[] {
    const isSuperAdmin = this.accountService.currentUser?.userRoles?.find(
      (r) => r.roleName === SuperAdmin
    );
    if (isSuperAdmin) {
      var SuperAdminSub = [
        {
          title: 'Super admin',
          icon: 'users',
          type: 'sub',
          active: false,
          children: [
            {
              title: 'Korisnici',
              type: 'link',
              icon: 'user',
              path: '/users',
            },
            {
              title: 'Role',
              type: 'link',
              icon: 'users',
              path: '/roles',
            },
            {
              title: 'Email log',
              type: 'link',
              icon: 'mail',
              path: '/email-log',
            },
            {
              title: '"Kupi" log',
              type: 'link',
              icon: 'dollar-sign',
              path: '/buy-log',
            },

            {
              title: 'Email newsletter',
              type: 'sub',
              children: [
                {
                  path: `/email-newsletter-template`,
                  title: 'Template',
                  type: 'link',
                },
                {
                  path: `/email-newsletter-template-send`,
                  title: 'Pošalji newsletter',
                  type: 'link',
                },
              ],
            },

            {
              title: 'Email naplata potraživanja',
              type: 'sub',
              children: [
                {
                  path: `/email-naplata-potrazivanja`,
                  title: 'Template',
                  type: 'link',
                },
                {
                  path: `/email-naplata-potrazivanja-send`,
                  title: 'Pošalji opomenu ',
                  type: 'link',
                },
              ],
            },

            {
              title: 'Operateri web pristup',
              type: 'sub',
              children: [
                { path: `/operateri`, title: 'Operateri', type: 'link' },
                {
                  path: `/operateri-web-pristup`,
                  title: 'Operateri web pristup',
                  type: 'link',
                },
              ],
            },

            {
              title: 'Izmijenjeni podaci',
              type: 'link',
              icon: 'dollar-sign',
              path: '/modified-entites',
            },

            {
              title: 'Obrisani podaci',
              type: 'link',
              icon: 'dollar-sign',
              path: '/deleted-entites',
            },

          ],
        },
      ];

      if (this.accountService.isCurrentUserZeljkoOrIgor) {
        var licence =
        {
          title: 'Licence pred istek',
          type: 'link',
          icon: 'dollar-sign',
          path: '/licence/pred-istek',
        }
        SuperAdminSub[0].children.push(licence);
      }

      return SuperAdminSub;
    }
    return [];
  }
}
